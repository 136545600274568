.wrapper{
    width: 96%;
    margin: 0 auto;
}
.LoginForm{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
.error{
    padding-top: 15px;
    text-align: center;
  }
.mainNav{
    display: flex;
    justify-content: space-between
}
.mainNav .navLink a{
    color:white;
    text-decoration: none;
}
.mainNav .balance {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

  .LoginForm .FormBtn{
      margin-top: 15px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    border: 1px solid green;
    -webkit-text-fill-color: green;
    -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
    transition: background-color 5000s ease-in-out 0s;
    }
    .person-list{
        margin-top: 30px;
    }
    .addPaymentBtn{
        text-align: right;
        position: fixed;
        right: 15px;
        bottom: 15px;
    }
    .addForm .item{
        display: flex;
        align-items: center;
        margin-bottom: 30px;

    }
    .addForm .item .itemLabel{
        min-width: 300px;
        display: flex;
        align-items: center;
    }
    .addForm .item .itemInput{
        min-width: 200px;
    }
    .CashItem .editBlock{
        width: 100px;
        min-width: 120px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .CashItem .editBlock .deleteBlock, .CashItem .editBlock .nextBlock{
        display: inline-block;
    }
    .dateFilterBlock{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .dateFilterBlock.hide{
        display:none;
    }
    .dateFilterBlock .center{
        position: relative;
        left: -50px;
    }
    .dateFilterBlock .item{
        margin-left: 15px;
        margin-right: 15px;
    }
    .CashItem .item input{
        font-size:14px;
    }
    .CashItem .item.val input{
        width: 120px;
    }
    .CashItem .item.name{
        width: -webkit-fill-available;
        max-width: 300px;
    }
    .CashItem .item.name .textField{
        width: 100%;
    }
    .CashItem .item.name input{
        width: 100%;
    }
    .addForm .item.name input{
        min-width: 400px;
    }
    .redBalance input{
        background-color: red;
        color:white !important;
    }
    .redBalance  input[type="number"][disabled] {
        color: white;
     }
     .orangeBalance input {
        background-color: orange;
        color: white !important;
     }
     .Spiner{
         position: fixed;
         left: 50%;
         top: 50%;
         transform: translateY(-50%) translateX(-50%);
     }
     .buttonFilter{
        position: relative !important;
        height: 100% !important;
        text-align: center !important;
        color: rgb(0, 166, 153) !important;
        margin-right: 8px !important;
        box-sizing: border-box !important;
        cursor: pointer !important;
        background: none !important;
        border-width: 2px !important;
        border-style: solid !important;
        border-color: rgb(0, 166, 153) !important;
        border-image: initial !important;
        padding: 4px 12px !important;
        font: inherit inherit inherit inherit inherit inherit inherit inherit !important;
        overflow: visible !important;
     }
     .buttonFilterBlock{
         text-align: center;
         padding: 5px; 
     }
     .naviToCharst button.active{
        background-color:#3f51b5;
     }
     .naviToCharst button.active svg{
         fill:white !important;
     }
     .DateRangePickerInput__withBorder{
        min-width: 306px;
     }
     .SearchBar{
         display: flex;
         align-items: center;
     }
     .SearchBarBlock{
         height: 30px !important;
     }
     .SearchBarBlock > div{
        bottom: 2px !important;
	 }
     .Search button{
        padding: 0px !important;
        width: 30px !important;
        height: 30px !important;
        margin: 0px -6px 0px 0px !important;
    
     }
          @media screen and (max-width: 1400px){
        .CashItem{
            flex-wrap:wrap;
            margin-bottom:15px;
        }
        .CashItem .item{
            margin-bottom: 5px;
        }
     }
