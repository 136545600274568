html {
  font-size: 12px;
}

.dropdown {
  background: white;
  z-index: 1;
}

.logo {
  cursor: pointer;
}

.Select--multi {
  height: unset !important;
}

.Select--multi .Select-multi-value-wrapper {
  /* height: 2.6rem !important; */
  height: unset!important;
  display: flex;
}

.Select--multi .Select-multi-value-wrapper div[role="button"] {
  height: 32px!important;
  margin: 0 2px 10px!important;
}

.Select--multi .Select-multi-value-wrapper > .Select-input {
  height: 2.6rem !important;
}

.MuiFormControlLabel-root > div {
  width: auto !important;
}

.page-padding {
  padding-bottom: 15%;
}

.MuiTableRow-root.audit-trails-row-create {
  background-color: #71ff6c;
}

.MuiTableRow-root.audit-trails-row-update {
  background-color: #f5ff6c;
}

.MuiTableRow-root.audit-trails-row-destroy {
  background-color: #ff6c6c;
}

.itemInput div .MuiChip-deletable{
  height: 32px!important;
  margin-bottom: 4px!important;
}